export type DomId =
  | "mediumrect_1_sidebar"
  | "job_1_sidebar"
  | "job_2_sidebar"
  | "job_1"
  | "job_2"
  | "mediumrect_1"
  | "mediumrect_2"
  | "mediumrect_3"
  | "sticky"
  | "top"
  | "skyscraber";

/** Ad unit codes defined in GAM */
type AdUnitCode =
  | "job_1"
  | "job_2"
  | "mediumrect_1"
  | "mediumrect_2"
  | "mediumrect_3"
  | "sticky"
  | "top"
  | "skyscraber";

type Breakpoints = "lg" | "xs";

type Settings = {
  readonly mapping: Record<number, ReadonlyArray<number>>;
  readonly sizes: ReadonlyArray<ReadonlyArray<number>>;
  readonly adUnitCode: AdUnitCode;
};

type Advertisement = Record<DomId, Settings>;

/* eslint-disable @typescript-eslint/no-magic-numbers -- these are the sizes defined in Google Ad Manager */
const bannerSize = {
  medium: [300, 250],
  skyscraber: [140, 600],
  small: [320, 160],
  sticky: [160, 600],
  top: [930, 180],
};
/* eslint-enable */

/** Unfortunately, GAM cannot handle rems, so we have to convert our tailwind breakpoints to px :( */
const mapTailwindBreakpointsToPx: Record<Breakpoints, number> = {
  lg: 1024, // equals to 64rem, when the rem unit is 16px (the browser default).
  xs: 0,
};

const { xs, lg } = mapTailwindBreakpointsToPx;

export const ads: Advertisement = {
  job_1: {
    adUnitCode: "job_1",
    mapping: {
      [lg]: [],
      [xs]: bannerSize.small,
    },
    sizes: [bannerSize.small],
  },
  job_1_sidebar: {
    adUnitCode: "job_1",
    mapping: {
      [lg]: bannerSize.medium,
      [xs]: [],
    },
    sizes: [bannerSize.medium],
  },
  job_2: {
    adUnitCode: "job_2",
    mapping: {
      [lg]: [],
      [xs]: bannerSize.small,
    },
    sizes: [bannerSize.small],
  },
  job_2_sidebar: {
    adUnitCode: "job_2",
    mapping: {
      [lg]: bannerSize.medium,
      [xs]: [],
    },
    sizes: [bannerSize.medium],
  },
  mediumrect_1: {
    adUnitCode: "mediumrect_1",
    mapping: {
      [lg]: [],
      [xs]: bannerSize.small,
    },
    sizes: [bannerSize.medium],
  },
  mediumrect_1_sidebar: {
    adUnitCode: "mediumrect_1",
    mapping: {
      [lg]: bannerSize.medium,
      [xs]: [],
    },
    sizes: [bannerSize.medium],
  },
  mediumrect_2: {
    adUnitCode: "mediumrect_2",
    mapping: {
      [lg]: bannerSize.medium,
      [xs]: bannerSize.small,
    },
    sizes: [bannerSize.small, bannerSize.medium],
  },
  mediumrect_3: {
    adUnitCode: "mediumrect_3",
    mapping: {
      [lg]: bannerSize.medium,
      [xs]: bannerSize.small,
    },
    sizes: [bannerSize.small, bannerSize.medium],
  },
  skyscraber: {
    adUnitCode: "skyscraber",
    mapping: {
      [lg]: bannerSize.skyscraber,
      [xs]: [],
    },
    sizes: [bannerSize.skyscraber],
  },
  sticky: {
    adUnitCode: "sticky",
    mapping: {
      [lg]: bannerSize.sticky,
      [xs]: [],
    },
    sizes: [bannerSize.sticky],
  },
  top: {
    adUnitCode: "top",
    mapping: {
      [lg]: bannerSize.top,
      [xs]: bannerSize.small,
    },
    sizes: [bannerSize.small, bannerSize.top],
  },
};
