"use client";

import { usePathname, useSearchParams } from "next/navigation";
import { useEffect, useState } from "react";

export function useCurrentUrl() {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const [currentUrl, setCurrentUrl] = useState("");

  useEffect(() => {
    const queryString =
      searchParams.size > 0 ? `?${searchParams.toString()}` : "";
    setCurrentUrl(`${pathname}${queryString}`);
  }, [pathname, searchParams]);

  return { currentUrl };
}
