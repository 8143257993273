"use client";

import { useEffect } from "react";
import type { SiteDomain } from "@/contexts/site/models/site.types";
import { useCookiebotConsent } from "@/hooks/cookiebot/use-cookiebot-consent.hook";
import { useCurrentUrl } from "@/hooks/use-current-url.hook";
import { useViewportBreakpoint } from "@/hooks/use-viewport-breakpoint.hook";
import type { ServerConfiguration } from "@/services/configuration/server-configuration.types";
import type { DomId } from "./ads";
import { ads } from "./ads";

type SetupAdsProps = {
  readonly domIds: ReadonlyArray<DomId>;
  readonly domain: SiteDomain | ServerConfiguration.AdUnitDomain; // Allow for test domains
};

/** The script itself is loaded inside _document, as it HAS to be loaded in head. */
export function useSetupAds({ domIds, domain }: SetupAdsProps) {
  const { marketing: hasMarketingConsent, submitted: hasSetConsent } =
    useCookiebotConsent();
  const { isMobile, currentBreakpoint } = useViewportBreakpoint();
  const { currentUrl } = useCurrentUrl();

  // For TESTING PURPOSES we have to hardcoded setups that can be pointed to - you will have to switch `domain` with either of the following:
  // 1) `watch_test1`: A campaign booked on all placements, including job ads, on 100% of the page views (which means that there is always a banner on ALL placements).
  // 2) `watch_test2`: A campaign booked with one banner on top and one banner on mediumrect_1. They are evenly distributed, meaning that either one ALWAYS has to be displayed.
  // This can e.g. be used for testing top banner expansion.
  const networkId = 83_172_459;
  const adUnitPath = `/${networkId}/${domain}/${
    hasMarketingConsent ? "web" : "web_noconsent"
  }/`;

  useEffect(() => {
    if (currentUrl === "" || currentBreakpoint === null || !hasSetConsent) {
      return;
    }

    // eslint-disable-next-line functional/immutable-data, @typescript-eslint/strict-boolean-expressions -- this is the recommended setup to avpid googtag to be undefined
    window.googletag = window.googletag || { cmd: [] };
    const { googletag } = window;

    googletag.cmd.push(
      () => {
        // Destroy any existing slots before pushing new ones
        googletag.destroySlots();

        googletag.pubads().collapseEmptyDivs();
        googletag.pubads().setCentering();

        for (const domId of domIds) {
          const { sizes, mapping, adUnitCode } = ads[domId];

          const adMapping = googletag.sizeMapping();

          for (const breakpoint of Object.keys(mapping)) {
            const breakpointSize = Number(breakpoint);
            adMapping.addSize([breakpointSize, 0], mapping[breakpointSize]);
          }

          const builtMapping = adMapping.build();

          googletag
            .defineSlot(`${adUnitPath}${adUnitCode}`, sizes, domId)
            .defineSizeMapping(builtMapping)
            .addService(googletag.pubads());
        }

        googletag.pubads().enableLazyLoad();
        googletag.pubads().enableSingleRequest();

        googletag.enableServices();
      },
      () => {
        for (const domId of domIds) {
          googletag.display(domId);
        }
      },
    );
  }, [
    currentUrl, // https://www.monterail.com/blog/gpt-ads-in-spa-next.js
    isMobile,
    hasMarketingConsent,
    hasSetConsent,
  ]);
}
