"use client";

import { useEffect, useState } from "react";
import { compareBreakpoints } from "@/utilities/compare-breakpoints";
import { withDebounce } from "@/utilities/with-debounce";

const definedBreakpoints = ["2xl", "lg", "md", "sm", "xl", "xs"] as const;
export type BreakpointValues = (typeof definedBreakpoints)[number];

const debounceDelay = 150;

type Viewport = {
  readonly currentBreakpoint: BreakpointValues | null;
  readonly isMobile: boolean;
  readonly isDesktop: boolean;
  readonly isTablet: boolean;
};

export function useViewportBreakpoint(): Viewport {
  const [currentBreakpoint, setCurrentBreakpoint] =
    useState<BreakpointValues | null>(null);

  useEffect(() => {
    handleResize();

    const debouncedHandleResize = withDebounce(handleResize, debounceDelay);
    window.addEventListener("resize", debouncedHandleResize);

    function handleResize() {
      const breakpoint = window
        .getComputedStyle(document.body, ":before")
        .content.replace(/"/gu, "") as BreakpointValues;

      const breakpointValue = definedBreakpoints.includes(breakpoint)
        ? breakpoint
        : null;

      setCurrentBreakpoint(breakpointValue);
    }

    return function cleanUp() {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  }, []);

  return {
    currentBreakpoint,
    isDesktop: compareBreakpoints(currentBreakpoint, ">=", "lg"),
    isMobile: compareBreakpoints(currentBreakpoint, "<", "lg"),
    isTablet: compareBreakpoints(currentBreakpoint, ">=", "md"),
  };
}
