"use client";

import { useServerConfiguration } from "@/contexts/server-configuration/server-configuration.context";
import { useSite } from "@/contexts/site/site.context";
import type { DomId } from "@/hooks/ads/ads";
import { useSetupAds } from "@/hooks/ads/use-setup-ads.hook";

type SetupPageAdSlotsProps = {
  readonly domIds: ReadonlyArray<DomId>;
};

/**
 * A small client component for setting up a page's ad slots.
 */
export function SetupPageAdSlots({ domIds }: SetupPageAdSlotsProps) {
  const { adUnitDomain } = useServerConfiguration();
  const { domain: currentSiteDomain } = useSite();
  const domain = adUnitDomain ?? currentSiteDomain;

  useSetupAds({
    domIds,
    domain,
  });

  return null;
}
